@use "./variables/colors" as *;
.ligaH2{
    margin-top: 20px;
    margin-bottom: 0;
}
.LeaguesCardsWrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: -25px;
    .LeaguesCardHolder{
        padding-bottom: 10px;
        color: black;
        width: 300px;
        min-height: 250px;
        height: auto;
        margin-top: 50px;
        box-shadow: $primary-box-shadow;
        border-radius: 20px;
        font-size: 20px;
        transition: all  ease .3s;
        position: relative;
        display: flex;
        
        justify-content: space-around;
        &:hover{
            // border: 20px solid rgb(183, 189, 191);
            box-shadow: $primary-box-shadow-hover;
            
            }
        img{
            max-height: 300px;
            width: 50%;
        }
        h2{
            color: black;
        }
    }
    
    }