@use "./variables/screensize.scss" as *;

.welcome{
    display: flex;
    flex-direction: column;
    .welcome-pic{
        display: none;
    }
    .welcome-Message{
        h2{
            margin-bottom: 0;
        }
        h1{
            margin-top: 0;
        }
    }
    @include lg{
        flex-direction: row;
        // max-width: 80%;
        .welcome-pic{
            display: block;
            text-align: right;
            img{
                width: 75%;
            }
        }
        .welcome-Message{
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            h2{
                margin-top: 0;
                font-size: 35px;
            }
            h1{
                margin-bottom: 0;
                font-size: 45px;
            }
            p{
                margin-top: 0;
                margin-bottom: 0;
                font-size: 20px;
            }
        }
    }
}
.newShirts{
    font-size: 30px;
    margin-bottom: 0;
}