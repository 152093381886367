@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');
// @use "./Font/Font.scss" as *;

main{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    max-width: 1150px;
}