@use "./variables/colors" as *;
@use "./variables/screensize.scss" as *;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');

.cardsHolder{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .ShirtCard{
        padding-bottom: 10px;
        width: 300px;
        min-height: 200px;
        height: auto;
        margin-top: 50px;
        box-shadow: $primary-box-shadow;
        border-radius: 20px;
        font-size: 20px;
        transition: all .3s;
        position: relative;
        &:hover{
        // border: 20px solid rgb(183, 189, 191);
        // box-shadow: 0 2px 10px rgba(33,33,33,.5);
        box-shadow: $primary-box-shadow-hover;
        .cardInfo{
            .imageHolder{
                img{
                    max-height: 270px;
                    // width: 80%;
                }
            }
        }
        }
    .cardInfo{
        color: black;
        
        p{
            margin-top: 0;
            margin-bottom: 5px;
        }
        h2{
            margin-top: 0;
            margin-bottom: 10px;
            font-family: "poppins", sans-serif;
            font-size: 1.75rem;
        }
        .league{
            color: $shirt-league-text-color;
            font-size: 15px;
        }
        .imageHolder{
            height: 270px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            // background-color: black;
            img{
            max-height: 250px;
            max-width: 75%;
            padding-top: 10px;
            border-radius: 20px;
            transition: all .5s;
            }
        }
        .matchworn{
            width: 50%;
            background-color: #118AB2;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 10px;
            color: white;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

}

.goBackWrapper{
    
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-top: 1rem;
.goBackButton{
    background-color: $primary-background-color;
    color: white;
    padding: .5rem;
    border-radius: 5px;
    margin-bottom: 0;
    margin-top: 4px;
    font-weight: bold;
    transition: all .3s;
    position: absolute;
    text-align: center;
    &:hover{
        box-shadow: 0 2px 10px rgba(33,33,33,.5);
    }
}
.shirtsLeagueH1{
    margin-top: 0;
    text-align: center;
    margin-bottom: 0;
    padding-top: 40px;
    @include lg{
            padding-top: 0;
    }
    // background-color: #118AB2;
}
}