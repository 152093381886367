@use "./variables/colors" as *;
@use "./variables/screensize.scss" as *;

@import url(https://fonts.googleapis.com/css2?family=Literata:wght@500;700&amp;family=Rubik:wght@400;500;700&amp;display=swap);
@import url(https://pro.fontawesome.com/releases/v5.2.0/css/all.css);

.createShirt, .createLeague{
    input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        height: 1px;
        width: 1px;
        background-color: black;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
      }
      [type="file"] + label {

        background: #f15d22;
        border: none;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: 'Rubik', sans-serif;
        font-size: inherit;
        font-weight: 500;
        margin-bottom: 1rem;
        outline: none;
        padding: 1rem 50px;
        position: relative;
        transition: all 0.3s;
        vertical-align: middle;

        &.btn-2 {
          background-color: $primary-background-color;
          border-radius: 50px;
          overflow: hidden;
          &::before {
            color: #fff;
            content: "\f382";
            font-family: "Font Awesome 5 Pro";
            font-size: 100%;
            height: 100%;
            right: 100%;
            margin-right: 5px;
            line-height: 3;
            position: absolute;
            top: 0px;
            transition: all 0.5s;
          }

          &:hover {
            background-color: darken($primary-background-color, 10%);

            &::before {
              right: 80%;
            }
          }
        }
      }
    input, select, textarea{
        width: 100%;
        border: 1px solid grey;
        border-radius: 10px;
        font-size: 20px;
        height: 40px;
        margin-bottom: 15px;
        background-color: white;
    }
    label{
        font-size: 17px;
    }
    button{
        background-color: #4bb543;
        color: white;
        border: none;
        border-radius: 15px;
        padding: 10px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        transition: all .4s;
        &:hover{
            box-shadow: 0 2px 14px rgba(75,181,67,.8);

        }
    }
    textarea{
        font-size: 17px;
        height: 150px;
    }
    #shirtFrontDisplay, #shirtBackDisplay, #fileNameDisplay{
        margin-top: -10px;
        margin-bottom: 25px;
    }
}
.shirtAdminCardsWrap{
display: flex;
flex-wrap: wrap;
justify-content: space-around;
margin-top: -50px;
.shirtAdminCardHolder{
    width: 300px;
    // height: 300px;
    margin-top: 50px;
    box-shadow: $primary-box-shadow;
    border-radius: 20px;
    color: black;
    padding-bottom: 20px;
    .imageHolder{
        height: 200px;
    img{
        max-height: 200px;
        width: 50%;
        border-radius: 5px;
    }}
    button{
        margin-top: 10px;
        border:none;
        background-color: crimson;
        color: white;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
        padding: 10px;
        border-radius: 15px;
        transition: all .4s;
        &:hover{
            box-shadow: 0 2px 14px rgba(220,20,60,.8);

        }
    }
}

}

//screen size small screen
@include lg {
    .createShirt, .createLeague{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}