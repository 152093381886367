@use "./variables/colors" as *;

.pageNotFound{
    img{
        width: 50%;
    }
    p{
        font-size: 20px;
    }
    .errorGoBackButton{
        background-color: $primary-background-color;
        color: white;
        padding: .5rem;
        border-radius: 5px;
        margin-bottom: 0;
        margin-top: 4px;
        margin-left: auto;
        margin-right: auto;
        transition: all .3s;
        max-width: 40%;
        text-align: center;
        &:hover{
            box-shadow: 0 2px 10px rgba(33,33,33,.5);
        }
    }
}