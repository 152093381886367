@charset "utf-8";
@use "../variables/colors" as *;
@use "../variables/screensize.scss" as *;


header{
  margin-bottom: 15px;
  box-shadow: $primary-box-shadow;
}
.header_logo{
    margin: 10px 0 10px 10%;
    width: 30%;
}

.wrapper{
  width: 100%;
  max-width: 1150px;
}

.wrapper nav{
  position: relative;
  display: flex;
  max-width: calc(100% - 50px);
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
nav .content{
  display: flex;
  align-items: center;
}
nav .content .links{
  margin-left: 0px;
  display: flex;
}

.content .links li{
  list-style: none;
  line-height: 30px;
}
.content .links li a,
.content .links li label{
  // color: #073B4c;
  color: $primary-background-color;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 17px;
  margin-right: 10px;
  border-radius: 20px;
  transition: all 0.3s ease;
  text-decoration: none;
  
  &:hover{
  background-color: rgba(129, 230, 217, 0.12);
  }
  &:active{
    background-color: rgba(98, 138, 132, 0.12);
  }
}



@include lg{
  .wrapper{
    margin-left: auto;
    margin-right: auto;
  }
}

/* Responsive code start */
@include lg{

  .social_icon_holder{
    margin-left: 5%;
  }
  .wrapper nav{
    max-width: 100%;
    padding: 0 20px;
  }
  nav .content .links{
    margin-left: 30px;

  }
  .content .links li a{
    padding: 8px 13px;    
    font-size: 23px;
  }
  .wrapper .search-box{
    max-width: calc(100% - 100px);
  }
  .wrapper .search-box input{
    padding: 0 100px 0 15px;
  }

  .SearchData{
    margin: auto;
  }
}





@media screen and (max-width: 900px){


  .social_icon_holder{
    display: none;
  }
  .logo {
  // height: 10px; 
  z-index: 9;
  // margin-top: -75px;
  img{
    height: 100px;
  }
}
  .wrapper .menu-icon{
    display: block;
  }
  .wrapper #show-menu:checked ~ .menu-icon i::before{
    content: "\f00d";
  }
  nav .content .links{
    display: block;
    position: fixed;
    background: #323c4e;
    height: 100%;
    width: 100%;
    top: 0px;
    left: -100%;
    margin: 0px 0px 0px 0px;
    padding: 170px 0px;
    max-width: 350px;
    overflow-y: auto;
    padding-bottom: 100px;
    transition: all 0.3s ease;
    z-index: 6;
  }
  nav #show-menu:checked ~ .content .links{
    left: 0%;
  }
  .content .links li{
    margin: 15px 20px;
  }
  .content .links li a,
  .content .links li label{
    line-height: 40px;
    font-size: 20px;
    display: block;
    padding: 8px 18px;
    cursor: pointer;
  }
  .content .links li a.desktop-link{
    display: none;
  }

  /* dropdown responsive code start */
  //Dropdown position
  .content .links ul,
  .content .links ul ul{
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    max-height: 0px;
    overflow: hidden;
  }
  //Dropdown position

  .content .links #show-features:checked ~ ul,
  .content .links #show-services:checked ~ ul,
  .content .links #show-items:checked ~ ul{
    max-height: 100vh;
  }
  .content .links ul li{
    margin: 7px 20px;
  }
  .content .links ul li a{
    font-size: 18px;
    line-height: 30px;
    border-radius: 5px!important;
  }
}





@media screen and (max-width: 400px){
  .wrapper nav{
    padding: 0 10px;
  }
  .content .logo a{
    font-size: 27px;
  }
  .wrapper .search-box{
    max-width: calc(100% - 70px);
  }
  .wrapper .search-box .go-icon{
    width: 30px;
    right: 0;
  }
  .wrapper .search-box input{
    padding-right: 30px;
  }
}


.SearchData{
  display: none;
  width: 55%;
  height: 100px;
  text-align: center;
  margin: -20px auto 0px auto;
  list-style-type: none;
}

.Search_input{
  border:solid 1px black;
}

.SearchLinks{
  color: #000000;
  font-size: 20px;
  background-color: rgb(255, 255, 255);
  border-bottom: solid 1px black ;
  display: flex;
  justify-content: center;
  align-content: flex-end;
  position: relative;
  z-index: 10;
  padding: 8px;
}

a:link{
   text-decoration: none;
}
