@use "./variables/screensize.scss" as *;

.sortByWrapper{
    // background-color: blue;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @include lg{
        width: 90%;
        justify-content: right;
        flex-direction: row-reverse;
    }
    .sortByHolder{
        // background-color: red;
        p{
            margin-bottom: 0;
            color: #494949;
        }
  
        select{
            padding: 7px 40px 7px 12px;
            width: 100%;
            border: 1px solid #E8EAED;
            border-radius: 5px;
            background: white;
            box-shadow: 0 1px 3px -2px #9098A9;
            cursor: pointer;
            font-family: inherit;
            font-size: 16px;
            transition: all 150ms ease;
            &:required:invalid{
            color: #5A667F;
            }
            option{
            color: #223254;
            
            &:focus{
            outline: none;
            border-color: #0077FF;
            box-shadow: 0 0 0 2px rgba(#0077FF,.2);
            }
            }}}
}
.cardsHolder{
    margin-top: -30px;
}