@use "./variables/colors" as *;
@use "./variables/screensize.scss" as *;

.ShirtCounter{
    // background-color: $primary-background-color;
    color: $primary-background-color;
    // width: 25%;
    // border-radius: 20px;
    h2{
        font-size: 20px !important;
        padding: 5px;
    }
    @include lg{
        width: 50%;
    }
}