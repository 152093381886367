@use "./variables/colors" as *;
footer{
    width: 100%;
    background-color: $primary-background-color;
    color: white;
    margin-bottom: 0;
    margin-top: 70px;
    padding-bottom: 1px;
    padding-top: 1px;
    p{
        padding-right: 15px;
        padding-left: 15px;
    }
}