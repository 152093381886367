@use "./variables/colors" as *;
.logoutButton{
    margin-top: 10px;
        border:none;
        background-color: crimson;
        color: white;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
        padding: 10px;
        border-radius: 15px;
        transition: all .4s;
        &:hover{
            box-shadow: 0 2px 14px rgba(220,20,60,.8);

        }
}
.editsHolder{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 35px;
    .editLinks{
        min-width: 300px;
        margin-bottom: 20px;
        color: black;
        box-shadow: $primary-box-shadow;
        border-radius: 20px;
        transition: all .4s;
        img{
        width: auto;
        max-height: 200px;
    }
    &:hover{
            text-decoration: underline;
        box-shadow: 0 2px 10px rgba(33,33,33,.5);

        }
    }
}
.loginHolder{
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1px;
    width: 100%;
    h3{
        margin-bottom: 0;
        font-size: 30px;
    }
.supabase-auth-ui_ui-container{
    margin-top: 0;
    // button{
    //     display: none;
    // }
    .c-egTDuJ-iwjZXY-color-default{
        display: none;
    }
    a{
        display: none;
    }
    input{
        width: 60%;
    }
    button{
        width: 60%;
        margin-right: auto;
        margin-left: auto;
    }
}
.supabase-auth-ui_ui-divider{
        display: none;
    }
}