@use "./variables/screensize.scss" as *;

.About{
    
    .AboutHolder{
        display: flex;
        flex-direction: column;
        align-items: center;
        .AboutText{
            width: 80%;
            text-align: left;
            padding: 15px;
        }
        .AboutImage{
            width: 75%;
            img{
                width: 100%;
                border-radius: 5px;
            }
        }
        @include lg{
                flex-direction: row-reverse;
                align-items: start;
                .AboutImage{
                    width: 50%;
                }
        }
    }
}