@use "./variables/colors" as *;
@use "./variables/screensize.scss" as *;
.backToLeagueWrapper{
    margin-right: auto;
    margin-left: auto;
    // width: 80%;
    width: 100%;
    text-align: left;
    margin-bottom: 1%;
    text-align: center;
    margin-bottom: 15px;
    @include lg{
        text-align: left;
        margin-left: 10%;
    }
    .backToLeague{
        color: white;
        // background-color: $primary-background-color;
        background-color:  $primary-background-color;
        padding: 5px;
        border-radius: 5px;
        font-weight: 500;
        transition: .2s ease-in-out;
        &:hover{
            color: $primary-background-color;
        background-color: rgba(129, 230, 217, 0.12);

        }
    }}
.shirtItemHolder{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    .shirtLeague:hover{
        text-decoration: underline;
    }
    
    .slick-prev{
        left: 65px;
        z-index: 1;
    }
    .slick-next{
        right: 65px ;
    }

    
    @include lg{
        width: 75%;
        flex-direction: row;
        .slick-prev{
            left: 90px ;

        }
        .slick-next{
            right: 90px ;

        }
    }
    .shirtImage{
        width: 100%;
        @include lg {
            width: 50%;
        }
        .slider{
        background-color: white;
        color: black;
            img{
                width: 65%;
                border-radius: 10px;
                margin-left: auto;
                margin-right: auto;
            } 
        }
    }

    article{
        text-align: left;
        margin-left: 20px;
        padding-top: 0;
        h1{
            font-size: 40px;
            margin-bottom: 0;
            margin-top: 15px;
            @include lg {
                margin-top: 0;
            }
        }
        .shirtLeague{
            color: $shirt-league-text-color;
            margin-top: 0;
            font-size: 15px;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    //screen size small screen
    // @media only screen and (min-width:  1024px) {
    //     flex-direction: row;
    //     .shirtImage{
    //         width: 60%;
    //     }

    //     article{
    //     h1{
    //         margin-top: 0;
    //     }
    //     }
    // }
    
    
}

.pageNotFound{
    .errorPic{
        width: 30%;
    }
    p{
        font-size: 20px;
    }
}